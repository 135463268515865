@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Transcend';
    src: url('./Assets/transcend-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.whatwedo-event-tabs {
    background-color: #0e0f13;
    color: #ffffff;
    padding: 30px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    max-width: 100%;
    margin: auto;
    border-radius: 10px;
}

.tab-menu {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
    gap: 30px;
    margin: 40px 0px 70px 0px;
}

.tab-button {
    background: none;
    border: none;
    color: #ffcc00;
    font-size: 2vw;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    padding: 10px 0;
    transition: color 0.3s ease-in-out;
}

.tab-button.active {
    color: #ffffff;
}

.tab-button.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 60%;
    height: 2px;
    background: rgba(255, 212,49 , 1);
    transform: translateX(-50%);
    transition: width 0.3s ease-in-out;
}

.content-1 {
    width: 80%;
    margin: auto;
    overflow: hidden;
}

.content-2 {
    width: 85%;
    margin: auto;
    overflow: hidden;
}

.content-2 .content-text {
    margin: 30px 0px;
}

.content-text {
    animation: fadeIn 0.5s ease-in-out;
    font-size: 1.2rem;
    line-height: 1.6;
    margin-top: 10px;
}

.image-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    margin: 50px 0px;
}

.image-grid div {
    border-radius: 10px;
    overflow: hidden;
}

.eventImage {
    width: 23vw;
    height: auto;
    transition: transform 0.5s;
}

.eventImage:hover{
    transform: scale(1.1);
}

.divider {
    margin: 5vw auto;
    width: 60%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media all and (max-width: 800px){
    .tab-button{
        font-size: 2.8vw;
    }

    .image-grid{
        grid-template-columns: auto auto;
    }

    .image-grid img{
        width: 42vw;
    }

    .tab-menu{
        grid-template-columns: auto auto auto;
    }
}

@media all and (max-width: 600px){
    .tab-button{
        font-size: 4vw;
    }

    .image-grid{
        grid-template-columns: auto auto;
    }

    .image-grid img{
        width: 40vw;
    }

    .content-1{
        width: 80%;
    }

    .content-2{
        width: 85%;
    }

    .tab-menu{
        grid-template-columns: auto auto;
    }
}