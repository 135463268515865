/* ImageGenerator.css */
.container1 {
  min-height: 100vh;
  width: 100vw;
  background-image: url('../Gallery/Assets/herosectionbackground.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.container1::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content-wrapper1 {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  transition: all 0.5s ease-in-out;
}

.hero-content1 {
  width: 100%;
  max-width: 600px;
  transition: all 0.5s ease-in-out;
  flex-shrink: 0;
}

.content-shifted1 .hero-content1 {
  max-width: 500px;
}

.headline1 {
  font-size: 2.25rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
}

.description1 {
  color: #f0f0f0;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.input-section1 {
  margin-bottom: 2rem;
}

.input-wrapper1 {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.text-input1 {
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 9999px;
  border: none;
  background-color: white;
  color: #333;
}

.text-input1:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.generate-button1 {
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: background-color 0.2s;
}

.generate-button1:hover {
  background-color: #333;
}

.generate-button1:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-message1 {
  color: #ff6b6b;
  margin-top: 0.5rem;
}

.terms-text1 {
  color: white;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.terms-link1 {
  color: white;
  text-decoration: underline;
}

.results-section1 {
  flex: 1;
  max-width: 800px;
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

.image-grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  width: 100%;
}

.image-container1 {
  aspect-ratio: 1;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  border-radius: 8px;
}

.fade-up {
  animation: fadeUp 0.5s ease-out forwards;
}

.image-container1:nth-child(1) { animation-delay: 0.1s; }
.image-container1:nth-child(2) { animation-delay: 0.2s; }
.image-container1:nth-child(3) { animation-delay: 0.3s; }
.image-container1:nth-child(4) { animation-delay: 0.4s; }
.image-container1:nth-child(5) { animation-delay: 0.5s; }
.image-container1:nth-child(6) { animation-delay: 0.6s; }

.image-container1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .content-wrapper1 {
    padding: 0 4rem;
    gap: 4rem;
  }
}

@media (max-width: 1024px) {
  .container1 {
    min-height: 100%;
    padding: 4rem 0;
  }

  .content-wrapper1 {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .hero-content1 {
    max-width: 600px;
    text-align: center;
  }

  .content-shifted1 .hero-content1 {
    max-width: 600px;
  }

  .results-section1 {
    max-width: 600px;
  }
}

@media (max-width: 640px) {
  .container1 {
    padding: 2rem 0;
  }

  .content-wrapper1 {
    padding: 0 1rem;
  }

  .headline1 {
    font-size: 1.875rem;
  }

  .input-wrapper1 {
    flex-direction: column;
    gap: 1rem;
  }

  .image-grid1 {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}