/* CSS for Contact and Appointment Forms */
body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: #fff;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-info {
  flex: 1;
  max-width: 45%;
  padding: 20px;
}

.contact-info h2 {
  font-size: 48px;
  margin-bottom: 10px;
  font-family: Poppins;
}

.contact-info p {
  margin: 10px 0;
  max-width: 350px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: Poppins;
}

.contact-details {
  margin-bottom: 20px;
}

.contact-details p {
  margin: 10px 0;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
}

.contact-details a {
  text-decoration: none;
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.contact-details a img {
  width: 20px;
  height: 20px;
  border-radius: 40%;
  padding: 10px;
}

.contact-details a:hover {
  color: #ffc107;
}

/* CSS for Social Icons */
.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.social-icons a img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  padding-right: 20px;
}

.info {
  display: flex;
  gap: 10px;
  font-family: Poppins;
  flex-wrap: wrap;
}

.brochure-buttons button {
  background-color: #FBD88D;
  border: none;
  color: #000;
  padding: 10px 20px;
  margin: 10px 15px 10px 0;
  cursor: pointer;
  border-radius: 9px;
  width: 218px;
  height: 45px;
}

.brochure-buttons button:hover {
  background-color: #ffc107;
}

/* Form Section */
.form-section {
  flex: 1;
  max-width: 45%;
  padding: 20px;
  background-color: black;
  border-radius: 8px;
}

.form-toggle {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  font-size: 18px;
  color: white;
  flex-wrap: wrap;
}

.form-toggle label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 20px;
}

.form-toggle input[type="radio"] {
  display: none;
}

.custom-radio {
  width: 20px;
  height: 20px;
  border: 2px solid #FBD88D;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
}

.form-toggle input[type="radio"]:checked + .custom-radio {
  background-color: #FBD88D;
  border-color: #000;
}

.custom-radio::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #FBD88D;
  border-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.form-toggle input[type="radio"]:checked + .custom-radio::after {
  transform: translate(-50%, -50%) scale(1);
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.input-row .input-container {
  flex: 1;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

input,
textarea {
  width: 100%;
  padding: 10px 7px;
  border: 1px solid #555;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #555;
  cursor: pointer;
}

input::placeholder,
textarea::placeholder {
  color: transparent;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #FBD88D;
}

form label {
  position: absolute;
  top: -10px;
  left: 10px;
  color: #aaa;
  font-size: 14px;
  background-color: black;
  padding: 0 5px;
  pointer-events: none;
  transition: all 0.3s ease;
}

.time-inputs {
  display: flex;
  gap: 4%;
  margin-bottom: 15px;
}

.time-inputs input {
  width: 85%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: black;
  color: #fff;
}

form button {
  background-color: #FBD88D;
  border: none;
  color: #000;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  width: 100%;
}

form button:hover {
  background-color: #ffd700;
}

.confirmation-message {
  text-align: center;
  margin-top: 20px;
}

.confirmation-message img {
  width: 108px;
  height: 108px;
  margin-bottom: 10px;
}

.confirmation-message h3 {
  font-size: 31px;
  margin-bottom: 10px;
  color: #FBD88D;
  font-family: Poppins;
}

.confirmation-message p {
  font-size: 18px;
  font-family: Poppins;
}

.purpose {
  height: 105px;
}

.message {
  height: 170px;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .form-container {
      padding: 10px;
  }

  .contact-info,
  .form-section {
      max-width: 100%;
      padding: 15px;
  }

  .contact-info h2 {
      font-size: 36px;
  }

  .contact-info p {
      font-size: 16px;
  }

  .input-row {
      flex-direction: column;
      gap: 0;
  }

  .brochure-buttons button {
      width: 100%;
      margin: 10px 0;
  }

  .time-inputs {
      flex-direction: column;
      gap: 15px;
  }

  .time-inputs input {
      width: 100%;
  }

  .social-icons {
      justify-content: center;
  }

  .social-icons a img {
      padding-right: 10px;
  }

  .form-toggle {
      flex-direction: column;
      gap: 10px;
  }

  .confirmation-message h3 {
      font-size: 24px;
  }

  .confirmation-message p {
      font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .contact-info h2 {
      font-size: 28px;
  }

  .form-toggle {
      font-size: 16px;
  }

  input,
  textarea {
      font-size: 14px;
  }

  form label {
      font-size: 12px;
  }

  .confirmation-message img {
      width: 80px;
      height: 80px;
  }
}