/* Navbar Container */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: transparent; /* Semi-transparent background */
  
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}
.navbar.scrolled {
  padding: 10px 20px; 
}

.logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.logo {
  max-height: 100px; /* Large size initially */
  transition: max-height 0.3s ease-in-out; /* Smooth resizing */
}

.logo.small {
  max-height: 50px;
}

.logo.normalLogoSize{
  max-height: 90px;
}

/* Hamburger Menu */
.hamburger-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  flex-direction: column;
  
  margin-left: auto; /* Automatically shift it to the far right */
  margin-right: 40px; /* Add spacing from the right edge */
}

.hamburger-menu .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

/* Menu Container */
.menu-container {
  position: fixed;
  top: -200%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url('../assets/navbar.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1999;
  transition: top 0.4s ease;
  padding-top: 50px;
  overflow-y: auto;
}

.menu-container.open {
  top: 0;
}

/* Dropdown Logo */
.dropdown-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.dropdown-logo img {
  max-width: 100%;
  height: auto;
  max-height: 100px;
}

.dropdown-logo img:hover {
  transform: scale(1.1); /* Enlarge logo slightly on hover */
  opacity: 0.8; /* Dim logo slightly */
}

/* Dropdown Menu */
.menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 80%;
}

.menu-container li {
  margin: 15px 0;
}

.menu-container li a {
  text-decoration: none;
  color: #fff;
  font-size: 36px;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

.menu-container li a:hover {
  color: #E1BB2B;
  transform: translateX(50px);
}

/* Arrow and Underline Effect */
.menu-container li a::before {
  content: "→";
  position: absolute;
  left: -20px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateX(-30px);
  color: #E1BB2B;
}

.menu-container li a:hover::before {
  opacity: 1;
  transform: translateX(2.2);
}

.menu-container li a::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffc107;
  transition: width 0.3s ease;
}

.menu-container li a:hover::after {
  width: 100%;
}

/* Close Button */
.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  transition: transform 0.3s ease;
}

.close-menu:hover {
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .menu-container li a {
      font-size: 28px;
  }

  .dropdown-logo img {
      max-height: 80px;
  }
}

@media (max-width: 768px) {
  .menu-container li a {
      font-size: 24px;
  }

  .dropdown-logo img {
      max-height: 60px;
  }
}

@media (max-width: 480px) {
  .menu-container li a {
      font-size: 20px;
  }

  .dropdown-logo img {
      max-height: 50px;
  }
}
