.tilted-gallery {
    background-color: #000;
    padding: 4rem 0;
    overflow: hidden;
  }
  
  .portfolio-gallery-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .images-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: -2rem;
    margin: 2rem 0;
    padding: 2rem 0;
    position: relative;
  }
  
  .image-card {
    flex: 0 1 600px;
    position: relative;
    transition: all 0.3s ease;
    z-index: 1;
    margin: 0 -2rem;
  }
  
  .image-card:hover {
    z-index: 2;
  }

  .images-wrapper .image-card:nth-child(1):hover {
    transform: rotate(7deg) scale(1.1);
  }
  .images-wrapper .image-card:nth-child(2):hover {
    transform: rotate(-18deg) scale(1.2);
  }
  .images-wrapper .image-card:nth-child(3):hover {
    transform: rotate(7deg) scale(1.1);
  }
  .images-wrapper .image-card:nth-child(4):hover {
    transform: rotate(-18deg) scale(1.2);
  }
  
  .image-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    filter: grayscale(100%);
    transition: all 0.3s ease;
  }
  
  .image-card:hover img {
    filter: grayscale(0%);
  }
  
  .portfolio-gallery-description {
    max-width: 1000px;
    margin: 6rem auto 0;
    text-align: center;
    color: #fff;
    line-height: 1.8;
    font-size: 1rem;
    padding: 0 1rem;
  }
  
  @media (max-width: 1024px) {
    .image-card {
      flex: 0 1 250px;
    }
  }
  
  @media (max-width: 768px) {
    .images-wrapper {
      flex-wrap: wrap;
      gap: 1rem;
    }
  
    .image-card {
      flex: 0 1 200px;
      margin: 0;
      transform: rotate(0deg) !important;
    }
  
    .portfolio-gallery-description {
      margin-top: 2rem;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .image-card {
      flex: 0 1 100%;
    }
  
    .tilted-gallery {
      padding: 2rem 0;
    }
  
    .images-wrapper {
      padding: 1rem 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .tilted-gallery {
    animation: fadeIn 1s ease-out;
  }

  .portfolio-grid-container {
    background-color: #000;
    padding: 2rem;
    min-height: 100vh;
  }
  
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .portfolio-grid-item {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .portfolio-item-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .portfolio-grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .portfolio-item-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    );
    padding: 2rem 1rem 1rem;
    transform: translateY(0);
    transition: transform 0.3s ease;
    text-align: start;
  }
  
  .portfolio-item-overlay h3 {
    color: white;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  /* Item specific styles */
  .portfolio-item-tall {
    grid-row: span 2;
    height: 600px;
  }
  
  .portfolio-item-short {
    /* grid-column: span 2; */
    height: 290px;
  }
  
  .portfolio-item-normal {
    height: 300px;
  }
  
  /* Hover effects */
  .portfolio-grid-item:hover img {
    transform: scale(1.05);
  }
  
  @media screen and (max-width: 1024px) {
    .portfolio-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  
    .portfolio-item-tall {
      height: 500px;
    }
  
    .portfolio-item-short {
      height: 250px;
    }
  
    .portfolio-item-normal {
      height: 250px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .portfolio-grid-container {
      padding: 1rem;
    }
  
    .portfolio-grid {
      grid-template-columns: 1fr;
    }
  
    .portfolio-item-tall,
    .portfolio-item-wide,
    .portfolio-item-normal {
      height: 300px;
      grid-row: span 1;
    }
  
    .portfolio-item-overlay h3 {
      font-size: 1.25rem;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .portfolio-grid-item {
    animation: fadeIn 0.6s ease-out forwards;
    animation-delay: calc(var(--item-index) * 0.1s);
    opacity: 0;
  }

  .portfolio-clients-section {
    background-color: #000;
    padding: 0rem 0 4rem;
    overflow: hidden;
    margin-bottom: 5rem;
  }
  
  .portfolio-clients-title {
    color: #FFD700;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    text-align: center;
    font-weight: 600;
    font-family: Transcend;
  }
  
  .portfolio-clients-slider {
    position: relative;
    overflow: hidden;
    padding: 1rem 0;
    margin: 0 -20px;
  }
  
  .portfolio-clients-track {
    display: flex;
    gap: 2rem;
    position: relative;
    animation: slideLeft 10s linear infinite;
    width: max-content;
    padding: 0 20px;
  }
  
  .portfolio-client-card {
    flex: 0 0 auto;
    width: 280px;
    background-color: white;
    border-radius: 10px;
    padding: 1.5rem;
    transition: transform 0.3s ease;
  }
  
  .portfolio-client-card:hover {
    transform: translateY(-5px);
  }
  
  .portfolio-client-logo-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    padding: 1rem;
  }
  
  .portfolio-client-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  @keyframes slideLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Performance Optimizations */
  .portfolio-clients-track {
    will-change: transform;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 1024px) {
    .portfolio-clients-title {
      font-size: 2rem;
    }
    
    .portfolio-client-card {
      width: 240px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .portfolio-client-card {
      width: 200px;
    }
    
    .portfolio-clients-track {
      gap: 1.5rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .portfolio-client-card {
      width: 180px;
    }
    
    .portfolio-clients-track {
      gap: 1rem;
    }
  }