.signature-events {
    padding: 4rem 0;
    color: white;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
  
  .main-title {
    font-family: Transcend;
    text-align: center;
    color: #FFD700;
    font-size: 3rem;
    margin: 1rem auto 1.5rem;
  }
  
  .subtitle {
    text-align: center;
    color: #fff;
    max-width: 700px;
    margin: 0 auto 5rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .events-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 2rem;
  }
  
  .event-section-card {
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid white;
    transition: transform 0.3s ease;
  }
  
  .event-section-image {
    position: relative;
    overflow: hidden;
  }
  
  .event-section-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
    .event-section-image img:hover {
    transform: scale(1.1);
  }
  
  .event-section-content {
    padding: 1.5rem;
  }
  
  .event-section-content h2 {
    margin-bottom: 0.75rem;
    font-size: 1.5rem;
  }
  
  .event-section-content p {
    color: #fff;
    line-height: 1.6;
    margin: 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 968px) {
    .events-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 640px) {
    .events-grid {
      grid-template-columns: 1fr;
    }
    
    .main-title {
      font-size: 2rem;
    }
    
    .signature-events {
      padding: 2rem 0;
    }
    
    .event-section-image {
      height: 180px;
    }
  }