.blog-card {
    color: #ffffff;
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 80%;
    margin: 4vw auto 0;
    border-radius: 8px;
}

.blog-heading {
    background: rgba(255, 212, 49, 1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    font-size: 2.3vw;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: Transcend;
}

.blog-image-container {
    margin-bottom: 15px;
    border-radius: 8px;
    overflow: hidden;
}

.blog-image {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.blog-date {
    font-size: 12px;
    color: #b3b3b3; 
    margin-bottom: 10px;
}

.blog-title {
    font-size: 2.4vw;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #ffffff;
}

.blog-description {
    font-size: 1vw;
    line-height: 1.6;
    color: #d1d1d1;
}

.blog-content {
    position: relative;
    padding-left: 20px;
}

.blog-content::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 40%;
    background-color: #FBD88D;
    border-radius: 5px;
}

@media all and (max-width: 1024px){
    .blog-heading {
        font-size: 3vw;
    }

    .blog-title {
        font-size: 2rem;
    }

    .blog-date{
        font-size: 1rem;
    }

    .blog-description {
        font-size: 1.2rem;
    }
}

@media all and (max-width: 800px){

    .blog-image{
        height: 300px;
    }

    .blog-card {
        padding: 10px;
    }

    .blog-heading {
        font-size: 4vw;
    }

    .blog-title {
        font-size: 2rem;
    }

    .blog-date{
        font-size: 1rem;
    }

    .blog-description {
        font-size: 1.2rem;
    }

    .blog-conclude h3{
        font-size: 3vw;
    }
}

/* RecentBlogPosts.css */
.recent-posts-container {
    padding: 5rem 8rem;
    background-color: #0a0a0a;
    margin-bottom: 4rem;
}

.recent-posts-main-heading {
    color: #FFD700;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-family: 'Transcend', sans-serif;
}

.recent-posts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 3rem;
}

.recent-posts-item {
    background-color: #111;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.recent-posts-img-wrapper {
    position: relative;
    padding-top: 66.67%; /* 3:2 aspect ratio */
    overflow: hidden;
}

.recent-posts-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.recent-posts-img:hover {
    transform: scale(1.1);
}

.recent-posts-item-title {
    color: #FFD700;
    font-size: 1.5rem;
    margin: 1rem;
}

.recent-posts-item-desc {
    color: #9ca3af;
    font-size: 0.875rem;
    line-height: 1.5;
    margin: 1rem;
}

.recent-posts-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
}

.recent-posts-numbers {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.recent-posts-page-number {
    background-color: transparent;
    border: 1px solid #FFD700;
    color: #FFD700;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.recent-posts-page-number:hover {
    background-color: #FFD700;
    color: #000;
}

.recent-posts-active {
    background-color: #FFD700;
    color: #000;
}

.recent-posts-ellipsis {
    color: #FFD700;
    padding: 0 0.5rem;
}

.recent-posts-nav-btn {
    background-color: transparent;
    border: 1px solid #FFD700;
    color: #FFD700;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.recent-posts-nav-btn:hover:not(:disabled) {
    background-color: #FFD700;
    color: #000;
}

.recent-posts-nav-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 1024px) {
    .recent-posts-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {

    .recent-posts-container {
        padding: 5rem 3rem;
    }

    .recent-posts-grid {
        grid-template-columns: 1fr;
    }
    
    .recent-posts-pagination {
        flex-direction: column;
    }
    
    .recent-posts-main-heading {
        font-size: 2rem;
    }
}